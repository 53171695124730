<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 4V7.5C13.5 8.05228 13.9477 8.5 14.5 8.5H18"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8 21H17C18.1046 21 19 20.1046 19 19V7.32843C19 6.79799 18.7893 6.28929 18.4142 5.91421L17.5 5L16.0858 3.58579C15.7107 3.21071 15.202 3 14.6716 3H7C5.89543 3 5 3.89543 5 5V18"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
